.DashboardReport {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: white;
  color: black;

  &:hover {
    color: black;
  }

  &.-clickable {
    border-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.05);

    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }

    &:active {
      box-shadow: none;
    }
  }

  &.-active {
    border-color: black;
    box-shadow: none;
    cursor: default;

    &:hover {
      background: white;
    }

    > .title {
      color: black;
    }
  }

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  > .value {
    line-height: 1;
    font-size: 2.25rem;
    font-weight: 500;
  }
}
