.DashboardTableLabel {
  display: inline-flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.25rem 0.75rem;
  font-size: 14px;
  white-space: nowrap;
}
