.Tooltip {
  background: black;
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  max-width: 10rem;
  text-align: center;
  z-index: 1000;
}
