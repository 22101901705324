.TableSelectCheckbox {
  padding: 0.675rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > .input {
    margin: 0;
    cursor: pointer;

    &:disabled {
      opacity: 0.25;
    }
  }
}
