@import "src/styles/mixins";

.DashboardReports {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}
