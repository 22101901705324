.DashboardTableHeader {
  > .title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  > .description {
    margin-top: 0.25rem;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
}
