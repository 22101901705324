@import "src/styles/mixins";

.DashboardTableFirstColumn {
  display: flex;
  align-items: center;
  padding-inline-start: 6px;

  @include media-breakpoint-up(md) {
    flex-basis: 250px;
  }
}
