@import "src/styles/mixins";

.DashboardLocationsChart {
  grid-column: span 2;

  @include media-breakpoint-down(lg) {
    grid-column: span 3;
  }

  @include media-breakpoint-down(md) {
    grid-column: span 6;
  }
}
