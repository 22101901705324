@import "variables";

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-width: 400px;

  [lang=he] & {
    direction: rtl;
  }
}

*:focus {
  outline: 0;
}

button {
  cursor: pointer;
}

.table {
  thead th {
    border-bottom: $table-border-width solid $table-border-color;
  }

  tbody + tbody {
    border-top: $table-border-width solid $table-border-color;
  }
}

input.form-control-sm {
  &[type="date"],
  &[type="datetime-local"],
  &[type="time"],
  &[type="month"],
  &[type="week"] {
    height: 28px;
  }
}

.modal-header .btn-close {
  margin-inline-start: auto;
  margin-inline-end: 0;
}

.page-item:not(:first-child) .page-link {
  margin-inline-start: $pagination-margin-start !important;
  margin-left: 0;
}
