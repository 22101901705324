.Header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  flex-wrap: wrap;
  padding: 0.5rem 1.75rem;
  border-bottom: 1px solid #ccc;
}
