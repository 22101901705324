@import "src/styles/variables";

.DashboardTableActionsButton {
  display: inline-flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.25rem;
  background: white;
  text-align: center;
  padding: 0.25rem 0.5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  white-space: nowrap;
  font-weight: $portal-font-weight-medium;

  &:hover {
    background: rgba(252, 252, 252, 0.95);
  }

  &:active {
    background: rgba(252, 252, 252, 0.9);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    box-shadow: none;
    background: white;
    color: black;
  }
}
