$gap: 6px;

.DashboardTableBatch {
  border-radius: $gap;
  background: rgba(0, 0, 0, 0.05);

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap;
    flex-wrap: wrap;
    padding: $gap;
    border-radius: $gap;
    cursor: pointer;
  }

  > .assets {
    display: flex;
    flex-direction: column;
    gap: $gap;
    padding: $gap;
    margin: 0 $gap $gap;
    background: white;
    border-radius: $gap - 2px;
  }
}
