@import "src/styles/mixins";

.DashboardIssuedChart {
  grid-column: span 4;

  @include media-breakpoint-down(lg) {
    grid-column: span 3;
  }

  @include media-breakpoint-down(md) {
    grid-column: span 6;
  }
}
