@import "src/styles/mixins";

.HeaderLinks {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 16px;
  height: 100%;
  margin-inline-end: auto;

  @include media-breakpoint-down(md) {
    width: 100%;
    order: 3;
  }
}
