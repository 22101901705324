@import "src/styles/variables";

.DropdownMenuToggle {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border: none;
  font-size: 20px;
  background: transparent;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}
